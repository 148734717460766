<template>
  <div class="content-full">
    <h3 class="dark-grey">Step 1: choose fund provider</h3>
    <p class="body">On occasion some clients simply want to make investments securely and efficiently without
      advice. so, we offer
      our clients
      the ability to do this here. We tend to offer investments via fund platforms wherever possible for ease of
      administration
      and wider fund choice, but direct investment with providers is also available.
      Below is a list of fund platforms we use often, please do note that other platforms are available. Simply click
      on the
      platform name to get further information about each platform including any charges and restrictions. All the
      fund
      platforms identified below provide clarity with daily valuation information so that you can always keep up to
      date with
      the value of your portfolio through our website.</p>
  </div>

  <div id="data-feed-info">
    <div class="clarity-margin-bottom-m clarity-margin-top-s">
      <h3 class="dark-grey">Platforms Providers</h3>
      <h5 class="blue">Available Online:</h5>
    </div>
    <div class="flex flex-wrap">
      <template v-for="providers in onlineProviders"
                :key="providers.id">
        <div class="flex clarity-margin-bottom-m column-providers">
          <div class="provider-logo-box flex align-items-center justify-content-center">
            <img class="provider-logo-image" :src="providers.logo">
          </div>
          <div class="flex" style="width: 70%">
            <div
                class="flex flex-column flex-grow-1 justify-content-between clarity-margin-right-m data-feed-info-body">
              <h3>{{ providers.name }}</h3>
              <p>{{ providers.description }}</p>
              <div class="flex">
                 <Button class="clarity-btn clarity-gradient-orange" @click="investWith(providers.name); $router.push('/investments/add/terms');">Invest Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div>
      <h5 class="blue">Offline only:</h5>
    </div>
    <div class="flex flex-wrap">
      <template v-for="providers in offlineProviders"
                :key="providers.id">
        <div class="flex clarity-margin-bottom-m column-providers" v-if="providers.id != 3">
          <div class="provider-logo-box flex align-items-center justify-content-center">
            <img class="provider-logo-image" :src="providers.logo">
          </div>
          <div class="flex" style="width: 70%">
            <div
                class="flex flex-column flex-grow-1 justify-content-between clarity-margin-right-m data-feed-info-body">
              <h3>{{ providers.name }}</h3>
              <p>{{ providers.description }}</p>
              <div class="flex">
                <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/securemessaging/message'); contactAdviser(providers.name);">Contact your Adviser
                </Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import api from "@/core/services/api";
import {toDrupalRequest} from "@/core/helpers";

export default {
  name: "StepChooseProvider",
  components: {},
  setup() {
    const datafeedProviders = ref([]);
    const datafeedStatus = ref([]);
    const store = useStore()

    // data feeds array and loading state is gathered from the store via the getters.
    const dataFeeds = computed(() => store.getters.dataFeeds)
    const dataFeedsLoading = computed(() => store.state.datafeeds.dataFeedsLoading)

    // @TODO: abstract this to store action - duplicated in datafeeds.vue
    const getProviders = async () => {
      const endpoint = '/valuation/datafeedplatforms';
      const response = await api.get(`${toDrupalRequest(endpoint)}`);
      if (response.error) {
        console.log('error');
      }

      datafeedProviders.value = response.providers;
      datafeedStatus.value = response.status;
    }

    // on mount ensure the data feeds call is made!
    onMounted(() => {
      // dispatch the action to load the data feeds.
      store.dispatch('getDataFeeds')
      store.dispatch('resetStepper', ['investnow'])
      getProviders();
    })

    const onlineProviders = computed(() => {
      return datafeedProviders.value.filter(provider => provider.active == 1 && provider.online == true);
    });

    const offlineProviders = computed(() => {
      return datafeedProviders.value.filter(provider => provider.active == 1 && provider.online == false);
    })

    const contactAdviser = (provider) => {
      store.dispatch('setSelectedStep', {stepper: 'investnowredirect', step: 2, completed: 1})
      store.dispatch('globalMessageTrigger',  {
        subject: 'Invest Now - ' + provider,
        category: 'Investments',
        recipient: store.getters.getAdviserName,
        messagebody: 'I would like to discuss investing via the '+provider+' platform.'
      })
    }

    const investWith = (provider) => {
      store.dispatch('setInvestNowProvider', provider)
      store.dispatch('setSelectedStep', {stepper: 'investnow', step: 2, completed: 1})
    }

    return {
      getProviders,
      datafeedProviders,
      dataFeeds,
      dataFeedsLoading,
      datafeedStatus,
      onlineProviders,
      offlineProviders,
      contactAdviser,
      investWith
    }
  }
}
</script>

<style scoped>
.content-full {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

button {
  min-width: 220px;
  justify-content: center;
  margin: 5px 5px 5px 0px;
  padding: 15px 45px;
}

.bottom-actions button {
  width: calc(100% / 4);
  padding: 0px 45px;
  margin: 0px 15px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.provider-logo-box {
  width: 300px !important;
  height: 120px;
  margin-right: 30px;
  background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--clarity-light-grey);
  border-radius: 27px;
  text-align: center;
}

.provider-logo-box img {
  height: auto;
  max-height: 60%;
  max-width: 240px;
}

.column-providers {
  width: 50%;
}

@media (max-width: 1200px) {
  .column-providers {
    width: 100%;
  }
}
</style>
